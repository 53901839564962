<template>
<div :class="cell_class()">
<a-row>
  <a-col :span="8"  justify="start">
    <a-space>
    <span style="text-align: left; font-weight: bold;"> {{ data.day }}</span>
      <a-button type="primary" shape="circle" size="small" class="bnAdd"
                @click="utils.router.edit_event('new', data.date)">+
      </a-button>
    </a-space>
  </a-col>
  <a-col :span="16" justify="end">
    <p style="text-align: right; font-weight: bold; margin-right: 2px;">
    <span style="color: gray"> {{ data.lunar_day }} </span>
    <span style="color: red"> {{ data.lunar_festival }} </span>
    <span style="color: red"> {{ data.gregorian_festival }} </span>
    <span style="color: red;color: yellowgreen;"> {{ data.special_festival }} </span>
    </p>
  </a-col>
</a-row>
    <span v-for="event in calFn.day_events(calendar, cell_moment)" :key="event.id">
      <a-tooltip>
        <template #title>{{event.remark}}</template>
        <a-button @click="utils.router.edit_event(event.id)" size="small" type="dashed" :class="event_class(event)">
          <span>{{event.subject}}</span>
        </a-button>
      </a-tooltip>
    </span>
</div>


</template>

<script>
import { defineComponent, inject} from 'vue';

export default defineComponent({
  name: "CalendarCell",

  props: {
    data: {type: Object},
    cell_moment : {type: Object}
  },

  setup(props){
    let calendar = inject('calendar');
    const calFn = inject('calendarFn');
    const utils = inject('utils');

    const cell_class = ()=>{
      if (calendar.selected_day_common.month !== props.data.month){
        return 'current_month_not calendar_cell';
      }

      if (calendar.selected_day_common.date === props.data.date){
        return 'today calendar_cell';
      }

      if (props.data.weekday >4){
        return 'holiday calendar_cell';
      }else{
        return 'current_month calendar_cell';
      }
    }

    function event_class(event){
      if (event.done){return 'event done';}
      if (event.event_type>=0 && event.event_type<1000){return 'event note';}
      if (event.event_type>=1000 && event.event_type<2000){return 'event agenda';}
      if (event.event_type>=2000 && event.event_type<3000){return 'event festival';}
      if (event.event_type>=8000 && event.event_type<9000){return 'event asset';}
      if (event.event_type>=9000 && event.event_type<10000){return 'event liabilities';}
      return 'event_normal'
    }

    return{
      calendar,
      calFn,
      utils,
      cell_class,
      event_class
    }
  }

})
</script>

<style scoped>



.done span {
  text-decoration:line-through;
  color:darkgray;
}

.event {margin-right: 2px;}

.done {background:transparent;}
.note {background:transparent;}
.agenda {background:lightgreen;}
.asset {background:yellow}
.liabilities {background:orangered;}
.festival {background:yellow;}

.event_normal {
  background:lightgreen;
  margin-right: 2px;
}

.calendar_cell{
  overflow: hidden;
  width: 100%;
  height:100px;
}

.current_month{
  background: lightgrey;
}

.current_month_not{
  background: whitesmoke;
}

.holiday{
  background: pink;
}

.today{
  background: greenyellow;
}

.calendar_cell .bnAdd{
  display: none;
  z-index: 1;
}

.calendar_cell:hover{
  background-color: bisque;
}

.calendar_cell:hover .bnAdd{
  display: block;
}

</style>