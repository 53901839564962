<template>
  <a-calendar :value="calendar.selected_moment" @panelChange="onPanelChange">
      <template #headerRender="{ value: current, onChange }">
        <div style="text-align: right; margin-right: 14px;">
        <a-space>

          <a-button shape="circle" size="middle" :disabled="calendar.updating"
                    @click="onChange(current.subtract(1,'month'))">
            <template #icon><LeftOutlined /></template>
          </a-button>

          <a-button type="primary" danger  shape="circle" size="middle" :disabled="calendar.updating"
                    @click="onChange(current.year(new Date().getFullYear()).month(new Date().getMonth()))">今
          </a-button>

          <a-button shape="circle" size="middle" :disabled="calendar.updating"
                    @click="onChange(current.add(1,'month'))">
            <template #icon><RightOutlined /></template>
          </a-button>

          <a-button type="primary" shape="circle" size="middle" :disabled="calendar.updating"
                    @click="utils.router.edit_event('new')"><template #icon><PlusOutlined /></template>
          </a-button>


          <a-select
              size="middle"
              :dropdown-match-select-width="false"
              class="my-year-select"
              :value="calendar.year"
              @change="newYear => {onChange(current.year(newYear));}">
                <a-select-option
                  v-for="val in getYears(current)"
                  :key="String(val)"
                  class="year-item"
                >
                  {{ val }}
                </a-select-option>
          </a-select>

          <a-select
                size="middle"
                :dropdown-match-select-width="false"
                :value="calendar.month<10?'0' + String(calendar.month):String(calendar.month)"
                @change="newMonth => {onChange(current.month(newMonth));}">
                >
                <a-select-option
                  v-for="val in [1,2,3,4,5,6,7,8,9,10,11,12]"
                  :key="String(val-1)"
                  class="month-item"
                >
                  {{ val }}
                </a-select-option>
              </a-select>

        </a-space></div>

      </template>

    <template #dateFullCellRender="{ current }">
      <CalendarCell :data="calFn.day_common(calendar, current)" :cell_moment="current"></CalendarCell>
    </template>
  </a-calendar>
  <Report v-if="calendar.data_ready" app="gnokCalendar" model="eventtodos" report_name="TodoList" :filters="{done:0}" :sorting="{action_time: 1}" />
</template>
<script>
import {defineComponent, inject, ref, watch} from 'vue';
import CalendarCell from "@/components/calendar/CalendarCell";
import { PlusOutlined , LeftOutlined, RightOutlined} from '@ant-design/icons-vue';
import Report from "@/components/Layout/Report";


export default defineComponent({
  components: {
    CalendarCell,
    Report,
    PlusOutlined,
    LeftOutlined,
    RightOutlined,
  },

  setup() {
    let moment = inject('moment');
    const calendar = inject('calendar');
    const calFn = inject('calendarFn');
    const utils = inject('utils');

    const calendar_event_modal_visible = ref(false);

    calendar.year = Number(calendar.selected_moment.format('yyyy'))
    calendar.month = Number(calendar.selected_moment.format('M'))

    const onSelect = value => {
      calendar.selected_moment = value;
      calendar.selected_day_common = calFn.day_common(calendar, value);
      calendar_event_modal_visible.value = true;
    };

    const onNewEvent = () => {
      // calendar_selected_cell_data.value = getCellData(value);
      utils.dict.sync_for_DOM(calendar.eventState, calendar.eventTemplate, moment);
      calendar.eventState.from_time = moment();
      calendar_event_modal_visible.value = true;
    };

    const onPanelChange = value => {
      calendar.selected_moment = value;
      calendar.year = Number(calendar.selected_moment.format('yyyy'))
      calendar.month = Number(calendar.selected_moment.format('M'))
      console.log('update_calendar onPanelChange')
      calFn.update_calendar(calendar, value);
    };

    watch(()=>calendar.need_update,() => {
      if (calendar.need_update){
        console.log('update_calendar need_update')
        calFn.update_calendar(calendar, calendar.selected_moment);
      }
    });

    const getMonths = value => {
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        months.push(localeData.monthsShort(value.month(i)));
      }
      return months;
    };

    const getYears = value => {
      const year = value.year();
      const years = [];

      for (let i = year - 20; i < year + 20; i += 1) {
        years.push(i);
      }

      return years;
    };

    console.log('update_calendar load body')
    calFn.update_calendar(calendar, calendar.selected_moment);

    return {
      calendar,
      utils,
      calFn,
      onSelect,
      onPanelChange,
      getMonths,
      getYears,
      onNewEvent,
    };
  },
});
</script>

<style scoped >
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>